.languageSwitchContainer {
  justify-content: flex-end;
  display: flex;
  padding-top: 20px;
}

.languageSwitchContainer .buttonTransparent {
  margin: 0;
  padding: 0 20px 0 10px;
}

.globeImg {
  height: 20px;
}
