.confirmationDialog p,
.confirmationDialog h2 {
  font-family: "AimoSans-Medium";
  color: black;
}

.confirmationDialog h2 {
  padding-left: 12px;
}

.confirmationDialog button {
  font-family: "AimoSans-Bold";
}

.confirmationDialog div {
  padding: 12px;
}

.confirmationDialog .bold {
  font-family: "AimoSans-Bold";
  margin-top: 16px;
}

.MuiPaper-root.MuiDialog-paper {
  margin: 8px;
}
