.App {
  text-align: center;
}
.flexWrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: "AimoSans-Bold";
}

.buttonBase,
.buttonBlue {
  font-family: AimoSans-Bold, serif;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  line-height: 1.2;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  font-size: 18px;
  padding: 0.8em 1.5em;
  margin: 1rem 0 0;
  color: #fff;
}

.buttonBase {
  background-color: #008f3c;
}

.buttonBlue {
  background-color: #002636;
}

.buttonBase.buttonTransparent {
  color: #000;
  background-color: transparent;
  font-family: "AimoSans-Regular";
}

.centered {
  margin: 0 auto;
}

.App .MuiTypography-root {
  font-family: "AimoSans-Bold";
}
