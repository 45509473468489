.requestContainer {
  max-width: 1164px;
  margin: auto;
  width: 100%;
  border-radius: 4px;
  padding: 1.5rem 1rem 2.5rem 1rem;
  box-sizing: border-box;
}

.requestContainer h1 {
  font-size: 36px;
  text-align: center;
}

.receiptIcon {
  width: 180px;
}

.inputField,
.numberInputField {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.numberInputField {
  margin-top: 1rem;
}

.cardNumberDigit {
  width: 60px;
  margin-right: 1rem;
  text-align: center;
}

.titleContainer {
  width: 100%;
}
.titleContainer img {
  width: 180px;
}

.phonePaymentInfo {
  margin-top: 8px;
  font-size: 14px;
  border-radius: 8px;
  padding: 12px;
  color: white;
  background-color: #002636;
}

.phonePaymentInfo a {
  color: #00ba4d;
}

@media only screen and (max-width: 1164px) {
  .requestContainer {
    margin: 1rem auto;
    width: 90%;
  }

  .inputContainer {
    width: 70%;
    max-width: 566px;
  }
}

@media only screen and (max-width: 566px) {
  .requestContainer {
    width: 95%;
  }

  .inputContainer {
    width: 90%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.select,
.textField {
  width: 100%;
}

.MuiInputBase-root {
  background-color: white;
}
