/*    FOOTER VIEW    */

.footerContainer {
  margin-top: 30px;
  font-family: "AimoSans-Bold";
  font-weight: 400;
}

.footerLinkContainer {
  display: flex;
  width: 400px;
  margin: 0;
  justify-content: center;
  font-size: 18px;
  background-color: #002636;
  color: #fff;
  height: 64px;
  width: 100%;
  padding-top: 28px;
  padding-left: 0;
  box-sizing: border-box;
  list-style: none;
}

.footerLinkContainer a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  position: relative;
}

.footerLinkContainer li {
  margin: 0 1rem;
}

.footerLogoContainer {
  background-color: #002636;
  display: flex;
  justify-content: flex-end;
  padding: 8px 40px 8px 0;
}

.footerLogoContainer img {
  height: 64px;
  margin-bottom: 32px;
}

.footerText:hover::after {
  content: "";
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -4px;
  background-color: white;
  position: absolute;
  animation: grow 0.2s linear;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 700px) {
  .footerLinkContainer {
    flex-direction: column;
    height: fit-content;
  }

  .footerLinkContainer li {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .footerLogoContainer {
    margin-top: 0;
    padding-top: 40px;
  }

  .footerLogoContainer img {
    height: 54px;
  }
}
