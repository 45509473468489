@font-face {
  font-family: "AimoSans-Medium";
  src: local("AimoSans-Medium"),
    url("../public/fonts/AimoSans-Medium.ttf") format("truetype"),
    url("../public/fonts/AimoSans-Medium.otf") format("opentype");
}

@font-face {
  font-family: "AimoSans-Regular";
  src: local("AimoSans-Regular"),
    url("../public/fonts/AimoSans-Regular.ttf") format("truetype"),
    url("../public/fonts/AimoSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "AimoSans-Bold";
  src: local("AimoSans-Bold"),
    url("../public/fonts/AimoSans-Bold.ttf") format("truetype"),
    url("../public/fonts/AimoSans-Bold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "AimoSans-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d6dfe3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 18px;
}

button:disabled {
  color: #49595e;
  background-color: #6d8085;
}

.App .MuiInputLabel-root,
.App .MuiMenuItem-root,
.App .MuiTableCell-root,
.App .MuiInput input::placeholder,
.App .MuiInputBase-root,
.MuiButtonBase-root.MuiMenuItem-root {
  font-family: "AimoSans-Medium";
}

.inputContainer {
  text-align: left;
  margin: auto;
  width: 50%;
}
